import * as React from 'react'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { ImageElement } from '@kentico/gatsby-kontent-components'
import { useTranslation } from 'react-i18next'
import { ArrowDirection } from '../../common/typings/enums'
import { AnimationService } from '../../common/AnimationService'
import { ImageQuality } from '../../common/typings/enums'
import ArrowButton from '../ArrowButton'
import './style.scss'

interface CaseStudyHeaderSectionClass {
  heading: string,
  description: string,
  image: ImageItem,
  backgroundColor: string,
  videoUrl: string,
  tags: string,
}
export interface CaseStudyHeaderProps extends CaseStudyHeaderSectionClass {
  className?: string
  arrowDirection?: ArrowDirection
}

const CaseStudyHeaderSection: React.FC<CaseStudyHeaderProps> = (caseStudyHeaderSectionData) => {
  const {
    heading,
    description,
    image,
    videoUrl,
    backgroundColor,
    tags,
    className,
  } = caseStudyHeaderSectionData
  const { t } = useTranslation()
  const tagsArray = tags.split('\n')
  const CONTENT_SECTION_ID = 'ContentSection'

  const headingRef = React.useRef<HTMLHeadingElement>(null)
  const textRef = React.useRef<HTMLParagraphElement>(null)
  const buttonRef = React.useRef<HTMLDivElement>(null)
  const imgRef = React.useRef<HTMLDivElement>(null)
  const tagsRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!AnimationService.isInView(imgRef.current)) {
      AnimationService.fadeIn(imgRef.current)
    }
    if (!AnimationService.isInView(headingRef.current)) {
      AnimationService.slideUp(headingRef.current)
    }
    if (!AnimationService.isInView(textRef.current)) {
      AnimationService.slideUp(textRef.current)
    }
    if (!AnimationService.isInView(tagsRef.current)) {
      AnimationService.slideUp(tagsRef.current)
    }
    if (!AnimationService.isInView(buttonRef.current)) {
      AnimationService.slideUp(buttonRef.current)
    }
  }, [])

  const imageQualityOptions = React.useMemo(() => ({ quality: ImageQuality.OPTIMIZED }), [])

  return (
    <section className={`CaseStudyHeaderSection GlobSectPadSpacing pl-m pr-m lg:pr-0 ${className || ''}`} style={{ backgroundColor: backgroundColor }}>
      <div className="SpacingAdjust pt-mobile-s lg:pt-s"></div>
      <div className="grid grid-cols-12 gap-x-m">
        <div className='TextWrapper col-span-12 lg:col-span-4 my-mobile-m lg:my-m lg:py-l'>
          <h1 className='FontXL mb-mobile-s lg:mb-s' ref={headingRef}>{heading}</h1>
          <p className='FontL mb-mobile-s lg:mb-s' ref={textRef}>{description}</p>
          <p className='FontS mb-mobile-m lg:mb-m flex flex-row flex-wrap' ref={tagsRef}>
            {tags && tagsArray.map((tag: string) => {
              return (
                <span className='Tags mr-mobile-s lg:mr-s' key={tag}>{tag}</span>
              )
            })}
          </p>
          <div className='Button' ref={buttonRef}>
            <ArrowButton title={t('common:scrollDown')} scrollToId={CONTENT_SECTION_ID} direction={ArrowDirection.DOWN} />
          </div>
        </div>
        <div className='ImageVideoWrapper col-span-12 lg:col-span-8 overflow-hidden flex relative w-screen transform -translate-x-m lg:-translate-x-0 lg:w-full' ref={imgRef}>
          {image && (
            <ImageElement
              image={image}
              layout="fullWidth"
              alt={image.description || heading}
              className="Image w-full"
              options={imageQualityOptions}
            />
          )}
          {videoUrl && (
            <video autoPlay muted loop playsInline className="Video overflow-hidden flex object-cover w-full">
              <source
                src={videoUrl}
                type="video/mp4"
              />
            </video>
          )}
        </div>
      </div>
    </section >
  )
}

export default CaseStudyHeaderSection