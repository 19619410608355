import * as React from 'react'
import { graphql, PageProps } from 'gatsby'
import { ImageItem } from '@kentico/gatsby-kontent-components/dist/image-element'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { IsMobileContext, LanguageContext } from '../common/Context'
import _get from 'lodash/get'

// components
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import ImageVideoSection from '../components/ImageVideoSection'
import ArticleContent from '../components/ArticleContent'
import LastCaseStudiesSection from '../components/LastCaseStudiesSection'
import ClientsSection from '../components/ClientsSection'
import Footer from '../components/Footer'
import FloatingButton from '../components/FloatingButton'
import CaseStudyHeaderSection from '../components/CaseStudyHeaderSection'
import InNumbers from '../components/InNumbersSection'
import Testimonials from '../components/TestimonialsSection/index'

// hooks, models
import { SeoData } from '../components/SeoComponent/model'
import { useLinksGridData, LinksGrid } from '../components/LinksGrid/model'
import { useNavbarData, Navbar as NavbarClass } from '../components/Navbar/model'
import { useLastCaseStudiesSectionData, LastCaseStudiesSection as LastCaseStudiesSectionClass } from '../components/LastCaseStudiesSection/model'
import { useClientsSectionData, ClientsSection as ClientsSectionClass } from '../components/ClientsSection/model'
import { useArticleContentData, ArticleContent as ArticleContentClass } from '../components/ArticleContent/model'
import { useImageVideoSectionData, ImageVideoSection as ImageVideoSectionClass } from '../components/ImageVideoSection/model'
import { useInNumbersDynamicData, InNumbersSection } from '../components/InNumbersSection/model'
import { useTestimonialsDynamicData, TestimonialsSection } from '../components/TestimonialsSection/model'
import { useNavbarDarkMode } from '../hooks/useNavbarInDarkMode'

import withSSR from '../hoc/withSSR'
import './case-study.scss'

interface CaseStudyHero {
  heading: string
  description: string
  image: ImageItem
  backgroundColor: string
  videoUrl: string
  tags: string
}

const CaseStudyPage: React.FC<PageProps> = (props) => {
  const { language: initialLanguage } = React.useContext(LanguageContext)
  const [language, setLanguage] = React.useState(initialLanguage)

  /*  For data collecting hooks below (e.g. useLinksGridData), 
  You need to know page codename from kentico and optionally section codename.
  Data service is searching query for this elements and collects the right data to components  */
  const mainPath = 'data.allKontentItemCaseStudy.nodes[0].elements'
  const elements = _get(props, `${mainPath}`, {})
  const siteMeta = _get(props, 'data.site.siteMetadata', {})
  const pagePathname = _get(props, 'location.pathname', '')
  const seoData = new SeoData({ ...elements, siteMeta }, pagePathname)
  const linksGridData = useLinksGridData(language, 'case_study_page')
  const navbarData = useNavbarData(language, 'case_study_page', pagePathname)
  const heroAssetType = _get(props, `${mainPath}.header_image_or_video.value[0].type`, '')
  const heroSectionData: CaseStudyHero = {
    heading: _get(props, `${mainPath}.title.value`, ''),
    description: _get(props, `${mainPath}.lead.value`, ''),
    image: heroAssetType.includes('image') ? _get(props, `${mainPath}.header_image_or_video.value[0]`) : '',
    backgroundColor: _get(props, `${mainPath}.header_background_color.value`, ''),
    videoUrl: heroAssetType.includes('video') ? _get(props, `${mainPath}.header_image_or_video.value[0].url`) : '',
    tags: _get(props, `${mainPath}.tags.value`, '')
  }
  const caseStudyContentData = useArticleContentData(_get(props, `${mainPath}`, ''))
  const inNumbersSectionData = useInNumbersDynamicData(props, mainPath)
  const testimonialsSectionData = useTestimonialsDynamicData(props, mainPath)
  const currentCaseId = _get(props, 'data.allKontentItemCaseStudy.nodes[0].id', '')
  const lastCaseStudiesSectionData = useLastCaseStudiesSectionData(language, currentCaseId)
  const clientsSectionData = useClientsSectionData(language, 'case_study_page')
  const imageVideoSectionData = useImageVideoSectionData(language, 'case_study_page', 'image_video_section')

  const pageData = {
    linksGridData,
    navbarData,
    heroSectionData,
    caseStudyContentData,
    inNumbersSectionData,
    testimonialsSectionData,
    lastCaseStudiesSectionData,
    clientsSectionData,
    imageVideoSectionData,
  }

  return (
    <Layout seoData={seoData} language={language} setLanguage={setLanguage}>
      <CaseStudyPageTemplateWithSSR pageData={pageData} />
    </Layout>
  )
}

interface CaseStudyPageProps {
  isMobile: boolean
  pageData: {
    linksGridData: LinksGrid
    navbarData: NavbarClass
    heroSectionData: CaseStudyHero
    caseStudyContentData: ArticleContentClass
    inNumbersSectionData: InNumbersSection | null
    testimonialsSectionData: TestimonialsSection | null
    lastCaseStudiesSectionData: LastCaseStudiesSectionClass
    clientsSectionData: ClientsSectionClass
    imageVideoSectionData: ImageVideoSectionClass
  }
}

const CaseStudyPageTemplate: React.FC<CaseStudyPageProps> = ({
  isMobile,
  pageData: {
    linksGridData,
    navbarData,
    heroSectionData,
    caseStudyContentData,
    inNumbersSectionData,
    testimonialsSectionData,
    lastCaseStudiesSectionData,
    clientsSectionData,
    imageVideoSectionData,
  }
}) => {
  const { t } = useTranslation()
  const navbarRef = React.useRef<React.ElementRef<typeof Navbar>>(null)
  const { navbarInDarkMode } = useNavbarDarkMode(isMobile, navbarRef)

  return (
    <IsMobileContext.Provider value={{ isMobile }}>
      <header>
        <Navbar {...linksGridData} {...navbarData} darkMode={navbarInDarkMode} ref={navbarRef} />
      </header>
      <main className="CaseStudyPage">
        <CaseStudyHeaderSection {...heroSectionData} />
        <section className="ContentSection GlobSectPadSpacing mx-mobile-xs lg:mx-m" id="ContentSection">
          <ArticleContent {...caseStudyContentData} />
        </section>
        {inNumbersSectionData && <InNumbers {...inNumbersSectionData} />}
        {testimonialsSectionData && <Testimonials {...testimonialsSectionData} />}
        <div className="HorizontalLine mt-mobile-l lg:mt-l mx-mobile-xs lg:mx-m mb-mobile-m lg:mb-m border-b border-separator border-opacity-separator"></div>
        <LastCaseStudiesSection {...lastCaseStudiesSectionData} lead={t('common:moreWorks')} />
        <ClientsSection {...clientsSectionData} />
        <ImageVideoSection {...imageVideoSectionData} />
      </main>
      <footer className="overflow-auto">
        <Footer {...linksGridData} />
      </footer>
      <FloatingButton />
    </IsMobileContext.Provider>
  )
}
const CaseStudyPageTemplateWithSSR = withSSR<CaseStudyPageProps>(CaseStudyPageTemplate)

export default CaseStudyPage

export const query = graphql`
  query($studyId: String, $language: String!) {
    locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allKontentItemCaseStudy(filter: {preferred_language: {eq: $language}, id: {eq: $studyId}}) {
      nodes {
        id
        preferred_language
        elements {
          title {
            value
          }
          slugs {
            value
          }
          lead {
            value
          }
          content {
            ...kontent_item_rich_text_element_valueFragment
          }
          listing_image_or_video {
            value {
              url
            }
          }
          tags {
            value
          }
          header_background_color {
            value
          }
          header_image_or_video {
            value {
              url
              description
              height
              name
              size
              type
              width
            }
          }
          meta_tags__meta_description {
            value
          }
          meta_tags__meta_image {
            value {
              url
            }
          }
          meta_tags__meta_keywords {
            value
          }
          meta_tags__meta_noindex {
            value {
              name
            }
          }
          meta_tags__meta_title {
            value
          }
          meta_tags__meta_type {
            value {
              name
            }
          }
          in_numbers_section {
            ...kontent_item_in_numbers_fragment
          }
          testimonials_section {
            ...kontent_item_testimonials_fragment
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`